import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import css from './Loader.module.scss'

const classNames = classNamesBind.bind(css)

interface OwnProps {
  size?: number | string
  blue?: boolean
  style?: React.CSSProperties
  className?: string
}

const Loader: FC<OwnProps> = ({
  size = 32,
  blue,
  style,
  className,
}: OwnProps) => {
  return (
    <svg
      className={className && className}
      style={style}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <rect x="0" y="0" width="100" height="100" fill="none"></rect>
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="rgba(255,255,255,0)"
        fill="none"
        strokeWidth="10"
        strokeLinecap="round"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="40"
        className={classNames('loader', { blue })}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
      >
        <animate
          attributeName="stroke-dashoffset"
          dur="2s"
          repeatCount="indefinite"
          from="0"
          to="502"
        ></animate>
        <animate
          attributeName="stroke-dasharray"
          dur="2s"
          repeatCount="indefinite"
          values="150.6 100.4;1 250;150.6 100.4"
        ></animate>
      </circle>
    </svg>
  )
}

export default Loader
